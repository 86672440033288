import { Injectable, makeStateKey} from "@angular/core";
import { Irequest, Manager } from "./Manager";
import { Iresponse } from "./RentManager";
import { AppComponent } from "../../app.component";
import { Erol } from "../interfaces/user.interface";


const request_is_requested = makeStateKey<any>('request_is_requested');
const request_get_all = makeStateKey<any>('request_get_all');

export interface Ivisitor {
  title : string,
  description : string,
  image : string,
  url : string
}

@Injectable({
  providedIn: 'root'
})
export class RequestManager extends Manager{


  all(data : {landlord ?: string|true, status ?: string}, response : Iresponse){

    let lnd = undefined;
    const user  = this.user()

    if(data.landlord !== undefined){
      if(data.landlord === true){
        if(user && (user.rol == Erol.LANDLORD || user.rol == Erol.ADMIN || user.rol == Erol.SA)){
          lnd = user.landlord ? `landlord=${user.landlord}` : undefined;
        }
      }else{
        if(user && (user.rol == Erol.ADMIN || user.rol == Erol.SA)){
          lnd = data.landlord ? `landlord=${data.landlord}` : undefined;
        }
      }
    }

    const where = data.status ? `status=${data.status.toLowerCase()}` : undefined;

    const request : Irequest = {
      url : `request/all`,
      key : request_get_all,
      body : [lnd,where]
    }

    this._get(request,response);
  }

  acceptOffert(request_id : number, response : Iresponse){
    const request : Irequest = {
      url : `request/accept-offer/${request_id}`,
    }

    this._post(request,response);
  }

  accept(request_id : number, landlord : string|true, data:any, response : Iresponse){
    let lnd = undefined;
    const user  = this.user()

    if(landlord === true){
      if(user && (user.rol == Erol.LANDLORD || user.rol == Erol.ADMIN || user.rol == Erol.SA)){
        lnd = user.landlord ? user.landlord : undefined;
      }
    }else{
      if(user && (user.rol == Erol.ADMIN || user.rol == Erol.SA)){
        lnd = landlord ? landlord : undefined;
      }
    }

    const request : Irequest = {
      url : `request/accept/${request_id}`,
      body : {
        landlord : lnd,
        data : data
      }
    }

    this._post(request,response);
  }

  reject(request_id : number, landlord : string|true,reason : string, response : Iresponse){
    let lnd = undefined;
    const user  = this.user()

    if(landlord === true){
      if(user && (user.rol == Erol.LANDLORD || user.rol == Erol.ADMIN || user.rol == Erol.SA)){
        lnd = user.landlord ? user.landlord : undefined;
      }
    }else{
      if(user && (user.rol == Erol.ADMIN || user.rol == Erol.SA)){
        lnd = landlord ? landlord : undefined;
      }
    }

    const request : Irequest = {
      url : `request/reject/${request_id}`,
      key : request_get_all,
      body : {
        landlord: lnd,
        reason : reason
      }
    }

    this._post(request,response);
  }

  apply(id : number, data: {arrive: string, depart: string, about : string}, response : Iresponse){

    const request : Irequest = {
      url : `request/${id}`,
      body : data
    }

    this._post(request,response);
  }

  cancel(request_id : number, reason :string, response : Iresponse){
    const request : Irequest = {
      url : `request/cancel/${request_id}`,
      body : {reason}
    }

    this._post(request,response);
  }

  isRequested(id :number,response : Iresponse){

    const request : Irequest = {
      url : `request/is-requested/${id}`,
      key: AppComponent.PRODUCTION ? request_is_requested : undefined
    }

    this._get(request,response);
  }

  sendEmail(id:number,url : string, code : string, about : string, arrive : string, depart: string, response : Iresponse){
    
    const request : Irequest = {
      url : `request/email/${id}`,
      body : {
        code : code,
        about : about,
        url : url,
        arrive: arrive,
        depart : depart
      },
    }

    this._post(request,response);

  }

}
