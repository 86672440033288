

export interface IRent {
    id : number,
    roomId : string,
    ha_id? : string,
    folder ?: IFolder,
    hidden?: boolean
    landlord: string,
    tag?: ITag,
    price : IPrice,
    url : string,
    title : string,
    location : ILocation,
    
    media : {
        images : string[],
        video ?: IVideoMedia
    },
    
    stay : IStay,
    bussy_dates ?: IDate[],

    listing ?: IListing;
    visitor_guide ?: {
        title : string,
        description: string,
        image : string,
        url : string
    }
    date_creation ?: string,
    date_update ?: string
} 


export interface IFolder {
    route : string,
    relative ?: string
}

export enum ERoomType {
    PRIVATE_APPARTMENT = "Private apartment",
    SINGLE_ROOM = "Single room",
    DOUBLE_ROOM  = "Double room",
    SHARED_ROOM = "Shared room",
    STUDIO = "Studio",
}

export enum EListingType {
    SHARED_HOUSE = "Shared house",
    APARTMENT = "Apartment",
    BED = "Bed",
    HOUSE = "House",
    STUDIO = "Studio"
}

export enum EAmenity {
    BARBECUE_AREA = "barbecue_area",
    BED_SHEETS = "bed_sheets",
    CLEANING_PRODUCTS = "cleaning_products",
    DRYER = "dryer",
    EQUIPPED_KITCHEN = "equipped_kitchen",
    GARDEN = "garden",
    LAUNDRY = "laundry",
    MICROWAVE = "microwave",
    PROFESSIONAL_CLEANING_COMMON_AREA = "professional_cleaning_common_area",
    PROFESSIONAL_DISINFECTION_COVID_19 = "professional_disinfection_covid_19",
    WASHING_MACHINE = "washing_machine",
    WIFI = "wifi",
    DISHWASHER = "dishwasher",
    TV_CABLE = "tv_cable",
    COUPLE = "couple",
    DESK = 'desk'
}

export enum EFacility {
    FARMACY = "farmacy",
    MARKETS = "markets",
    SHOPS = "shops",
    PARK = "park",
    FREE_PARKING = "free_parking",
    PLAYGROUND = "playground"
}

export interface IAmenities {
    [EAmenity.BARBECUE_AREA]: boolean;
    [EAmenity.BED_SHEETS]: boolean;
    [EAmenity.CLEANING_PRODUCTS]: boolean;
    [EAmenity.DRYER]: boolean;
    [EAmenity.EQUIPPED_KITCHEN]: boolean;
    [EAmenity.GARDEN]: boolean;
    [EAmenity.LAUNDRY]: boolean;
    [EAmenity.MICROWAVE]: boolean;
    [EAmenity.PROFESSIONAL_CLEANING_COMMON_AREA]: boolean;
    [EAmenity.PROFESSIONAL_DISINFECTION_COVID_19]: boolean;
    [EAmenity.WASHING_MACHINE]: boolean;
    [EAmenity.WIFI]: boolean;
    [EAmenity.DISHWASHER]: boolean;
    [EAmenity.TV_CABLE]: boolean;
    [EAmenity.COUPLE]: boolean;
    [EAmenity.DESK]: boolean;
}

export interface IFacilities {
    [EFacility.FARMACY]: boolean;
    [EFacility.MARKETS]: boolean;
    [EFacility.SHOPS]: boolean;
    [EFacility.PARK]: boolean;
    [EFacility.FREE_PARKING]: boolean;
    [EFacility.PLAYGROUND]: boolean;
}

export enum EUnit {
    M2 = 'm²'
}

export interface IVideoMedia {
    link : string|null,
    image : string
}

export interface IMeasures {
    size ?: number|null,
    room_size ?: number | null,
    unit : EUnit|string
}

export interface IDateHour {
    start_hour : string,
    end_hour : string,
    closed : boolean
}

export interface IOpenningHours{
    monday_to_friday ?: IDateHour,
    saturday ?: IDateHour,
    sunday ?: IDateHour
}

export enum EBedType{
    SINGLE = "Single",
    DOUBLE = "Double",
}

export interface IBed {
    total : number,
    available : number,
    type : EBedType |string
}

export interface IInformation {
    bedrooms : number,
    beds : IBed,
    guest : number,
    bathrooms : number,
    allow_additional_guests: boolean,
    measures ?: IMeasures,
    openning_hours ?: IOpenningHours;
}

export interface ITag {
    color : string,
    bgColor: string,
    name : string
}

export interface ILocation{
    address : string,
    latitud: number, 
    longitud: number,
    zipCode?: string
}

export interface ICurrency{
    text : string,
    symbol : string
}

export enum EPaymentTime {
    MONTH = 'month',
    BED = 'night'
}

export interface Iutilities{
    electricity : number|'included'|'prepay',
    gas : number|'included'|'prepay',
    internet : number|'included'|'prepay',
    water: number|'included' |'prepay'
}

export interface IPrice {
    money : number,
    first_month ?: number,
    time : EPaymentTime | string,
    currency : ICurrency,
    security_deposit ?: number,
    tax ?: number,
    reservation ?:  number,
    prepay_fields ?: string[],
    utilities? : Iutilities,
    arrangement? : {
        price : number,
        text: string
    }
    monthly ?: any
}

export interface IStayRange{
    min ?: number|null,
    max ?: number|null,
}

export interface IStay{
    day ?: IStayRange,
    month ?: IStayRange,
    year ?: IStayRange,
    
    strict_range ?: boolean,

    checkin ?: string,
    checkout ?: string
}

export interface ITermsRules{
    rules : Rules,
    additional ?: string,
}

export class Rules{
    smoking: boolean = false;
    pets: boolean = false;
    party: boolean = false;
    children: boolean = false;
}

export enum EFlooring{
    LAMINATE = 'laminate',
    CARPET =  'carpet',
    STORE = 'store',
    WOOD = 'wood',
    PLASTIC = 'plastic',
    OTHER = 'other'
}

export enum EBathroom {
    PRIVATE = 'private',
    NO = 'no',
    WITHBOYS = 'withBoys',
    WITHGIRLS = 'withGirls',
    WITHBOTH = 'withBoth'
}

export enum EOptions {
    PRIVATE = 'private',
    NO = 'no',
    SHARED = 'shared'
}

export enum EHeating {
    NO = 'no',
    CENTRAL = 'central',
    GAS = 'gas',
    WOODSTOVE = 'woodStove',
    ELECTRICAL = 'electrical'
}

export enum EPreferredGender {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other'
}

export interface IListing {
    terms_rules : ITermsRules
    information : IInformation,
    roomType : ERoomType | string,
    listingType : EListingType | string,
    amenities : IAmenities,
    facilities: IFacilities,
    age_min : number,
    age_max : number,
    about : string
    balcony_terrace ?: EOptions,
    preferredGender ?: EPreferredGender
    garden ?: EOptions
    kitchen  ?: EOptions
    kitchenware  ?: EOptions
    livingRoom ?: EOptions
    parking ?: EOptions
    toilet ?: EOptions
    bathroom ?: EBathroom
    flooring ?: EFlooring
    heating ?: EHeating
}

export interface IDate {
    start : string;
    end : string;
}


export const Bed = {
    getIcon : (bedType ?: EBedType|string) => {
        switch(bedType){
            case EBedType.SINGLE:
                return "single_bed";
            default :
                return "bed";
        }
    }
}

export const RoomType = {
    getIcon : (roomType ?: ERoomType|string) => {
        switch(roomType){
            case ERoomType.PRIVATE_APPARTMENT:
                return 'cottage'
            case ERoomType.SINGLE_ROOM :
                return 'door_front';
            case ERoomType.SHARED_ROOM:
                return 'group';
            case ERoomType.DOUBLE_ROOM :
                return 'door_sliding'
            case ERoomType.STUDIO:
                return 'location_away'
            default :
                return null;
        }
    }
}

export const Amenity = {
    getIcon : (amenity : string) => {
        switch(amenity){
            // case EAmenity.AIR_CONDITIONING:
            //     return 'ac_unit';
            case EAmenity.CLEANING_PRODUCTS:
                return 'household_supplies';
            case EAmenity.EQUIPPED_KITCHEN:
                return 'countertops';
            case EAmenity.WASHING_MACHINE:
                return 'local_laundry_service';
            case EAmenity.LAUNDRY:
                return 'faucet';
            case EAmenity.PROFESSIONAL_DISINFECTION_COVID_19:
                return 'sanitizer';
            case EAmenity.TV_CABLE:
                return 'tv';
            case EAmenity.BARBECUE_AREA:
                return 'outdoor_grill';
            case EAmenity.DISHWASHER:
                return 'dishwasher';
            case EAmenity.GARDEN:
                return 'gate';
            case EAmenity.MICROWAVE:
                return 'microwave_gen';
            // case EAmenity.SAUNA:
            //     return 'sauna';
            case EAmenity.BED_SHEETS:
                return 'bed_sheets';
            case EAmenity.DRYER:
                return 'cool_to_dry';
            // case EAmenity.GYM:
            //     return 'fitness_center';
            case EAmenity.PROFESSIONAL_CLEANING_COMMON_AREA:
                return 'cleaning';
            // case EAmenity.SWIMMING_POOL:
            //     return 'pool';
            case EAmenity.WIFI:
                return 'wifi';
            default:
                return null;
        }
    }
}

export const Facility = {
    getIcon : (facility ?: string) => {

        switch(facility){
            case EFacility.FARMACY:
                return 'medication';
            case EFacility.FREE_PARKING:
                return 'garage';
            case EFacility.MARKETS:
                return 'store';
            case EFacility.PARK:
                return 'nature_people';
            case EFacility.PLAYGROUND:
                return 'fence'
            case EFacility.SHOPS:
                return 'shopping_bag';
            default:
                return null;
        }
    } 
}