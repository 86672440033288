<a style="outline: none;" href="/dashboard" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuLogin" (mouseover)="openMenu(menuTrigger)" (mouseleave)="closeMenu(menuTrigger)">
  <button class="mon-logo-user" [ngClass]="{'active': isActive}" mat-icon-button style="outline: none;
  width: 42px !important;
  text-align: center;
  height: 42px !important;
  justify-content: center;
  display: flex;
  align-items: center;">
    <img width="32" height="32" (error)="handleError($event)" [ngStyle]="{'filter' : profileImage ? 'unset' : null , 'width' : profileImage? '100%' : null,'height' : profileImage? '100%' : null }" [src]="profileImage ?? iconMON" alt="logo">
  </button>
</a>


<mat-menu class="cdk-user-header" #menuLogin="matMenu" [hasBackdrop]="false">
  <div (mouseover)="blockMenuTop()" (mouseleave)="closeMenu(menuTrigger,2)">

    @for(dsh of getDashboard().links ; track $index){
      <a href="/dashboard/{{dsh.link}}" mat-menu-item style="text-decoration: none; color: inherit;" [ngClass]="{'activo':(dsh.link ? urlActive.includes(dsh.link) : urlActive.endsWith('dashboard'))}">
        <mat-icon>{{dsh.icon}}</mat-icon>
        <span style="font-family: Montserrat;">{{dsh.name}}</span>
      </a>
    }

    @if(getDashboard().links && getDashboard().links.length){
      <button style="min-height: 1em;" mat-menu-item [disabled]="true"> </button>
    }

    @for(dsh of getDashboard().userLinks ; track $index){
      <a href="/dashboard/{{dsh.link}}" mat-menu-item style="text-decoration: none; color: inherit;" [ngClass]="{'activo':(dsh.link ? urlActive.includes(dsh.link) : urlActive.endsWith('dashboard'))}">
        <mat-icon>{{dsh.icon}}</mat-icon>
        <span style="font-family: Montserrat;">{{dsh.name}}</span>
      </a>
    }

    @if(getDashboard().userLinks && getDashboard().userLinks!.length){
      <button style="min-height: 1em;" mat-menu-item [disabled]="true"> </button>
    }

    @for(dsh of getDashboard().buttons ; track $index){
      <a href="/dashboard/{{dsh?.link}}" style="text-decoration: none; color: inherit;">
        <button (click)="dsh.click ? dsh.click($event) : null" mat-menu-item>
          <mat-icon>{{dsh.icon}}</mat-icon>
          <span style="font-family: Montserrat;">{{dsh.name}}</span>
        </button>
      </a>
    }

  </div>
</mat-menu>
