


export const GLOBAL_FORMAT = 'YYYY-MM-DD';

export const FORMAT_DATE =  {
    parse: {
      dateInput: 'DD/MM/YYYY'
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
    }
};