import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EAmenity, EFacility, ERoomType } from '../../../core/interfaces/rent.interface';
import { FilterFindHomeService } from '../../../core/services/filter-find-home.service';
import { Utils } from '../../../core/utils/Utils';



@Component({
  selector: 'app-filters-find-home',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule,MatCheckboxModule,
    ReactiveFormsModule,FormsModule,
    MatButtonModule],
  templateUrl: './filters-find-home.component.html',
  styleUrl: './filters-find-home.component.scss'
})
export class FiltersFindHomeComponent {




  readonly numOfBeds = ["Nº of beds",...Array.from({length: 16}, (_, i) => ((i+1 )))];
  readonly numOfRooms = ["Nº of rooms",...Array.from({length: 16}, (_, i) => ((i+1 )))];
  readonly roomType = ["Accomodation type", ...Object.values(ERoomType)];
  readonly minAr = ["Min", ...Array.from({length: 50}, (_, i) => ((i+3 )* 100))];
  readonly maxAr = ["Max",...Array.from({length: 50}, (_, i) => ((i+3 )* 100))];

  readonly amenities : string[] = Object.values(EAmenity);
  readonly facilities : string[] = Object.values(EFacility);

  readonly moreAmenities = []
  
  readonly moreFacilities = []

  visibleAmenities : boolean = false;
  visibleFacilities : boolean  = false; 
  
  @Output() close : EventEmitter<any> = new EventEmitter();


  form : FormGroup = new FormGroup({
    beds : new FormControl(null),
    rooms : new FormControl(null),
    type : new FormControl(null),
    min : new FormControl(null),
    max: new FormControl(null),
    amenities : new FormControl([]),
    facilities : new FormControl([])
  })

  constructor(private changes : ChangeDetectorRef, private filtersService : FilterFindHomeService){
    
  }



  replaceBar(str : string){
    return Utils.capitalize(str.replaceAll('_',' '));
  }

  toggle(value : string, checked : boolean, control : any){
    
    value = value;
    const list = control!.value ?? [];
    
    
    if(!checked){
      const index = list.findIndex((v : string) => v == value);
      if(index == -1) return;
      list.splice(index,1);
    } else{
      list.push(value);
    }

    control.setValue(list);
  }

  toggleAmen(event: MatCheckboxChange) {
    const value = event.source.value;
    this.toggle(value,event.checked,this.form.get('amenities')!);
  }

  toggleFacil(event: MatCheckboxChange) {
    const value = event.source.value;
    this.toggle(value,event.checked,this.form.get('facilities')!);
  }

  more(element : HTMLElement, type: number = 0){

    element.classList.toggle('expanded');

    if(type == 0){
      this.visibleAmenities = !this.visibleAmenities
      this.changes.detectChanges();
    }else{
      this.visibleFacilities = !this.visibleFacilities
      this.changes.detectChanges();
    }

  }

  reset () {
    this.form.reset();
    this.filtersService.filters(null);
    this.close.emit(true);
  }

  apply(){
    const body = this.form.getRawValue();
    this.close.emit(true);
    this.filtersService.filters(body);
  }

}
