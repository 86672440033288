import { inject, Injectable } from '@angular/core';

import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {


  private _snackBar = inject(MatSnackBar);
  private _horizontal : MatSnackBarHorizontalPosition = 'center';
  private _vertical : MatSnackBarVerticalPosition = 'top';
  private _option : MatSnackBarConfig = {
    horizontalPosition: this._horizontal,
    verticalPosition: this._vertical, 
  }

  constructor() { }


  /**
   * 
   * @param text 
   * @param duration  in seconds
   */
  text(text : string, type : 'ok'|'warn'|'error'|'info' ,  duration : number|false = 3) {
    
    let close_button : boolean = false;
    if(duration !== false) this._option.duration = duration * 1000;
    else close_button = true;
    
    this._option.data = { text , close_button, type}
    this._option.panelClass = ['snackbar-panel-class-custom']  
    this._option.panelClass.push(type);

    this._snackBar.openFromComponent(SnackbarComponent, this._option);
  }


  close(){
    this._snackBar.dismiss();
  }

}
