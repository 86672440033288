import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild, Input, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { FilterFindHomeService } from '../../core/services/filter-find-home.service';
import moment from 'moment';
import { GLOBAL_FORMAT } from '../../data/const/format-date';
import { Router } from '@angular/router';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    MatIconModule,
    CommonModule,
    FormsModule
  ],
  standalone: true,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ],
})
export class CalendarComponent  {


  @Input({required: true})
  startDate!: FormControl<any>;

  @Input({required : true})
  endDate!: FormControl<any>;

  @Input()
  searchButton : boolean = true;

  currentDate: Date = new Date();
  displayMonths: Date[] = [
    new Date(),
    new Date(new Date().setMonth(this.currentDate.getMonth() + 1))
  ];
  hoverDate: Date | null = null;
  isOpen: boolean = false;
  openTimeout : boolean = false;

  readonly WEEKDAYS: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  readonly MONTHS: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  activeInput: 'checkin' | 'checkout' | null = null;
  @ViewChild('calendarWrapper') calendarWrapper!: ElementRef;
  @ViewChild('calendarContent') calendarContent!: TemplateRef<any>;
  
  private overlayRef: OverlayRef | null = null;
  
  constructor(private filtersService : FilterFindHomeService, private _router : Router,private overlay: Overlay, 
    private changes : ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef){
    
  }

  private openCalendarOverlay(): void {
    if (this.overlayRef) {
      return;
    }

    const positions: ConnectedPosition[] = [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 8
    }];

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.calendarWrapper)
      .withPositions(positions)
      .withPush(false) // Importante: evita que el overlay sea empujado
      .withViewportMargin(8);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const overlayConfig: OverlayConfig = {
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: ['calendar-overlay-panel', 'calendar-overlay-animation'],
      width: '672px',
      maxWidth: '672px'
    };

    this.overlayRef = this.overlay.create(overlayConfig);
    
    this.overlayRef.backdropClick().subscribe(() => {
      this.closeCalendarOverlay();
    });

    const portal = new TemplatePortal(this.calendarContent, this.viewContainerRef);
    this.overlayRef.attach(portal);
    
    this.isOpen = true;
    this.changes.detectChanges();

    // Forzar la actualización de la posición después del render
    setTimeout(() => {
      if (this.overlayRef) {
        const position = this.overlayRef.getConfig().positionStrategy as any;
        position.apply();
      }
    }, 0);
  }


  private closeCalendarOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.isOpen = false;
    }
  }

  // Asegúrate de limpiar el overlay cuando el componente se destruye
  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  getDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  getFirstDayOfMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  }

  formatDate(date: Date | null): string {
    if (!date) return '';
    return date.toLocaleDateString('en', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }

  moveMonths(direction: number): void {
    this.displayMonths = this.displayMonths.map(date => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + direction);
      return newDate;
    });
  }

  handleDateClick(date: Date): void {
    // Si la fecha está deshabilitada, no hacemos nada
    if (this.isDateDisabled(date)) {
      return;
    }
  
    // Caso 1: No hay fecha de llegada seleccionada
    if (!this.startDate.value) {
      this.startDate.setValue(date);
      this.activeInput = 'checkout'
      this.endDate.setValue(null)
      return;
    }
  
    // Caso 2: Si solo hay fecha de llegada
    if (this.startDate.value && !this.endDate.value) {
      // Si clica en un día previo válido, movemos la fecha de llegada
      if (date < this.startDate.value) {
        this.startDate.setValue(date);
      } else {
        // Si es posterior, la establecemos como fecha de salida
        this.endDate.setValue(date);
      }
      return;
    }
  
    if (this.startDate.value && this.endDate.value) {
      if (date < this.startDate.value) {
        this.startDate.setValue(date);
      } else {
        this.endDate.setValue(date);
      }
    }
  }

  isDateInRange(date: Date): boolean {
    if (!this.startDate.value) return false;
    if (!this.endDate.value && this.hoverDate) {
      return date >= this.startDate.value && date <= this.hoverDate || 
             date >= this.hoverDate && date <= this.startDate.value;
    }
    return this.endDate.value ? date >= this.startDate.value && date <= this.endDate.value : false;
  }


  handleDateHover(date: Date): void {
    if (this.startDate.value && !this.endDate.value) {
      this.hoverDate = date;
    }
  }

  isDateDisabled(date: Date): boolean {
    return date < new Date(this.currentDate.setHours(0, 0, 0, 0));
  }

  isDateSelected(date: Date): boolean {
    return (this.startDate.value && date.getTime() === this.startDate.value.getTime()) ||
           (this.endDate.value && date.getTime() === this.endDate.value.getTime()) || false;
  }

  isStartDate(date: Date): boolean {
    return this.startDate.value ? date.getTime() === this.startDate.value.getTime() : false;
  }

  isEndDate(date: Date): boolean {
    return this.endDate.value ? date.getTime() === this.endDate.value.getTime() : false;
  }

  clearDates(): void {
    this.startDate.setValue(null);
    this.endDate.setValue(null);
  }

  getMonthDays(monthDate: Date): (Date | null)[] {
    const days: (Date | null)[] = [];
    const firstDay = this.getFirstDayOfMonth(monthDate);
    const daysInMonth = this.getDaysInMonth(monthDate);

    // Add empty spaces for the first days
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    // Add the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(new Date(monthDate.getFullYear(), monthDate.getMonth(), day));
    }

    return days;
  }


  handleInputClick(inputType: 'checkin' | 'checkout'): void {
    // Si es el input de checkout y no hay fecha de inicio, no hacemos nada
   

    // Si el calendario ya está abierto y se hace clic en el mismo input, lo cerramos
    if (this.isOpen && this.activeInput === inputType) {
      this.isOpen = false;
      this.closeCalendar();
      this.activeInput = null;
      setTimeout(()=>this.openTimeout = false,200);
      return;
    }

   
    this.updateDisplayMonths(window);
    this.activeInput = inputType;
    this.isOpen = true;
    this.openCalendarOverlay();
    this.openTimeout = true;
    setTimeout(()=>{
      this.updateCalendarDropDown();
    },10)
  } 


  isMobile: boolean = false;
  @ViewChild('inputsContainer') inputsContainer!: ElementRef;
  @ViewChild('calendarDropdown') dropdown!: ElementRef;
  @ViewChild('calendarWrapper') wrapper!: ElementRef;

  getDropdownPosition(): number {
    if (!this.inputsContainer || !this.dropdown || !this.wrapper) return 0;
    
    const containerRect = this.inputsContainer.nativeElement.getBoundingClientRect();
    const dropdownRect = this.dropdown.nativeElement.getBoundingClientRect();
    const wrapperRect = this.wrapper.nativeElement.getBoundingClientRect();
    
    // Calcula la posición para centrar
    const centerPosition = containerRect.left + (containerRect.width / 2) - (dropdownRect.width / 2);
    
    return centerPosition - wrapperRect.left + 20;
  }

  updateCalendarDropDown(){
    if(!this.dropdown) return;
    if(this.dropdown.nativeElement)
      this.dropdown.nativeElement.style.left = `${this.getDropdownPosition()}px`;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    
    // Verificar si el clic fue dentro de un input de fecha
    const isDateInput = clickedElement.closest('.date-input');
    
    // Verificar si el clic fue dentro del dropdown del calendario
    const isCalendarDropdown = clickedElement.closest('.calendar-dropdown');
    
    // Si no se hizo clic ni en los inputs ni en el dropdown, cerrar el calendario
    if (!isDateInput && !isCalendarDropdown) {
      this.closeCalendar()
    }
  }


  closeCalendar(){
    this.isOpen = false;
    this.activeInput = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const window = event.target as Window;
    
    this.updateCalendarDropDown();
    this.updateDisplayMonths(window);
  }

  ngOnInit() {
    // Inicialización inicial
    if(typeof window !== 'undefined')
      this.updateDisplayMonths(window);
  }

  private updateDisplayMonths(w : any) {
    this.isMobile = w.innerWidth < 768;
    this.displayMonths = [
      new Date(),
      ...(this.isMobile ? [] : [new Date(new Date().setMonth(this.currentDate.getMonth() + 1))])
    ];
  }


  search() {
    const arrive = moment(this.startDate.value)?.format(GLOBAL_FORMAT);
    const depart = moment(this.endDate.value)?.format(GLOBAL_FORMAT);
    this.filtersService.dates({start : arrive, end : depart})
    this._router.navigateByUrl(`/rent-rooms-dublin`);
    this.filtersService.emit();
    this.closeCalendar();
  }

}