import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserIconService {


  private dataSubject = new BehaviorSubject<boolean>(false);
  private data$: Observable<boolean> = this.dataSubject.asObservable();

  
  constructor() { 

  }

  update(){
    this.dataSubject.next(true);
  }

  public observable () : Observable<boolean> {
    return this.data$;
  }

}
