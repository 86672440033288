import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../../core/services/api.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';


enum MODE_RESTORE {
  FIRST,
  SEND_CODE,
  SEND_PASSWORD
}

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatProgressBarModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  form : FormGroup = new FormGroup({
    email : new FormControl(null),
    code: new FormControl(null),
    password: new FormControl(null),
    repeat_password : new FormControl(null),
  });

  errorConfirm : string = '';
  confirmed : boolean = false;
  MODE_RESTORE = MODE_RESTORE;
  disabledConfirm : boolean = false;

  private _option : MODE_RESTORE = MODE_RESTORE.FIRST
  public get option() : MODE_RESTORE {return this._option}
  manyOfCodes : number = 6;
  readonly codes : number[] = new Array(this.manyOfCodes).fill(0);
  constructor(private api : ApiService){
    for(let i = 0 ; i < this.manyOfCodes ; ++i){
      this.form.addControl('code'+i,new FormControl(null,[Validators.required]));
    }
  }

  getError(control: string) {
    let form : FormGroup = this.form;
    const formControl : any = form.get(control)!;

    const errors : any = formControl.errors;
    if(errors?.required){
      return 'This field is required.';
    }
    if(errors?.[control] ?? false){
      return errors[control];
    }
    return '';
  }
  restorePassword(){
    this.errorConfirm = '';
    if(this.option == MODE_RESTORE.SEND_PASSWORD){
      if(this.differentPassword()) {
        this.form.get('repeat_password')?.setErrors({'repeat_password':'The passwords do not match. Please ensure they are identical.'})
        return;
      }
    }else if(this.option == MODE_RESTORE.SEND_CODE){
      let values : string = "";
      for(let i = 0 ; i < this.manyOfCodes ; ++i){
        const value = this.form.get('code'+i)?.value;
        values += value;
        if(!value){
          this.errorConfirm = 'Please, fill al the fields.'
          return;
        }
      }

      this.form.get('code')?.setValue(values);
    }

    const body = this.form.getRawValue();
    const method: string = 'auth/password/restore';
    this.disabledConfirm = true;
    const REST_API = this.api.post({body,method}).subscribe({
      next : (result) => {
        REST_API.unsubscribe();
        this.disabledConfirm = false;
        if(result){
          if(this._option == MODE_RESTORE.FIRST){
            this._option = MODE_RESTORE.SEND_CODE;
            this.form.get('code')?.setValue('INVALID');
          }else if(this._option == MODE_RESTORE.SEND_CODE){
            this._option = MODE_RESTORE.SEND_PASSWORD;
          }else{
            this.confirmed = true;
            this.form.get('password')?.disable();
            this.form.get('repeat_password')?.disable();
          }
        }else{
          if(this._option == MODE_RESTORE.SEND_CODE){
            this.errorConfirm = 'The code entered is incorrect.'
          }
        }
      },
      error : (error) => {
        this.disabledConfirm = false;
        if(error == 'ERROR_EXPIRED'){
          this.errorConfirm = 'The code has expired'
        }else{
          this.form.get('email')?.setErrors({'email':'The email address provided is invalid or does not exist.'});
        }
        REST_API.unsubscribe();
      }
    })

  }

  differentPassword(){
    const password = this.form.get('password')?.value
    const repeat_password = this.form.get('repeat_password')?.value

    return password != repeat_password;
  }


  codesContainer : any = null;
  validateCode(event : any,pos : number){
    this.errorConfirm = '';
    let value = event.target.value;
    if(Number.parseInt(value) == Number.NaN) return;
    this.codesContainer = this.codesContainer ? this.codesContainer : document.getElementById('codes-container');
    const control = this.form.get('code'+pos);
    if(!value || !value.trim()) return;
    if(value.length > 1){
      value = value.charAt(0);
      control?.setValue(value);
    }
    if(this.codesContainer.children.length > pos + 1){
      this.codesContainer.children[pos+1].focus();
    }
  }

  cleanCode(pos: number) {
    const control = this.form.get('code'+pos);
    control?.setValue(null);
  }
  pasteCode(event: ClipboardEvent) {
    const text  : string | undefined =  event.clipboardData?.getData('text');
    for(let i = 0 ; i < this.manyOfCodes ; ++i){
      this.form.get('code'+i)?.setValue(null);
    }
    if(!text) return;
    if(Number.parseInt(text)==Number.NaN) return;

    for(let i = 0 ; i < Math.min(this.manyOfCodes,text.length) ; ++i){
      this.form.get('code'+i)?.setValue(text[i]);
    }

    if(text.length == this.manyOfCodes){
      this.restorePassword();
    }

  }
}
