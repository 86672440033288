<div class="center-filters" [formGroup]="form">
  
  <section class="filter-group">
    <strong>Size</strong>
    <div class="filters">
      <mat-form-field>
        <mat-label>{{numOfBeds[0]}}</mat-label>
        <mat-select formControlName="beds" disableRipple panelClass="change-panel-select">
          @for(selectFilter of numOfBeds ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{numOfRooms[0]}}</mat-label>
        <mat-select formControlName="rooms" disableRipple panelClass="change-panel-select">
          @for(selectFilter of numOfRooms ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{roomType[0]}}</mat-label>
        <mat-select formControlName="type" disableRipple panelClass="change-panel-select">
          @for(selectFilter of roomType ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
  </section>

  <section class="filter-group">
    <strong>Price</strong>
    <div class="filters">
      <mat-form-field>
        <mat-label>{{minAr[0]}}</mat-label>
        <mat-select formControlName="min" disableRipple panelClass="change-panel-select">
          @for(selectFilter of minAr ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{maxAr[0]}}</mat-label>
        <mat-select formControlName="max" disableRipple panelClass="change-panel-select">
          @for(selectFilter of maxAr ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
  </section>
  <section #divFilterAmenities class="filter-group">
    <strong>Amenities</strong>
   <div style="display: flex; flex-direction: row; width: 100%; max-width: 1845px;">
    <div style="display: flex; flex-direction: column;  width: 100%;">
      <div class="filters" style="width: 100%;">
        @for(amen of amenities ; track $index){
          <mat-checkbox [checked]="form.get('amenities')!.value?.includes(amen)" (change)="toggleAmen($event)" [value]="amen">{{replaceBar(amen)}}</mat-checkbox>
        }
      </div>
     
      @if(visibleAmenities){
        <div class="filters" style="width: 100%;">
          @for(amen of moreAmenities ; track $index){
            <mat-checkbox [checked]="form.get('amenities')!.value?.includes(amen)" (change)="toggleAmen($event)" [value]="amen">{{replaceBar(amen)}}</mat-checkbox>
          }
      </div>
      }
    </div>
    <!-- <button (click)="more(divFilterAmenities)" type="button" style="color: #db332a;" mat-button>
      More 
      <mat-icon>more_vert</mat-icon>
    </button> -->
   </div>
    
    

  </section>
  <section #divFilterFacilities  class="filter-group">
    <strong>Facilities</strong>
    <div style="display: flex; flex-direction: row; width: 1220px;">
      <div style="display: flex; flex-direction: column; max-width: 1133px; width: 100%;">
        <div class="filters" style="width: 100%;">
          @for(facil of facilities ; track $index){
            <mat-checkbox [checked]="form.get('facilities')!.value?.includes(facil)" (change)="toggleFacil($event)" [value]="facil">{{replaceBar(facil)}}</mat-checkbox>
          }
        </div>
        @if(visibleFacilities){
          <div class="filters" style="width: 100%;">
            @for(facil of moreFacilities ; track $index){
              <mat-checkbox [checked]="form.get('facilities')!.value?.includes(facil)" (change)="toggleFacil($event)" [value]="facil">{{replaceBar(facil)}}</mat-checkbox>
            }
        </div>
        }
        
      </div>
      <!-- <button (click)="more(divFilterFacilities,1)" type="button" style="color: #db332a;" mat-button>
        More 
        <mat-icon>more_vert</mat-icon>
      </button> -->
    </div>
   
  </section>

  <section class="button-selection">
    <button (click)="reset()" type="button" mat-button style="font-family: Montserrat;">Reset</button>
    <button (click)="apply()" type="button" style="color: #db332a; font-family: Montserrat;" mat-button>Apply Filters</button>
  </section>
</div>
