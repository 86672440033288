import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class FilterFindHomeService {
  

  private fil : any ;
  private _dates ?: {start : string|null|undefined, end : string|null|undefined};

  private dataSubject = new BehaviorSubject<any>(undefined);
  private data$: Observable<boolean> = this.dataSubject.asObservable();
  private observing : boolean = false;
   
  constructor() { 
 
  }
 
 
  filters(fil : any){   
    this.fil = fil;
    this.emit();
  }
  dates(date : {start : string|null|undefined, end : string|null|undefined}){
    this._dates = date;
  }

  emit() {
    this.dataSubject.next(this.get());
  }

  get(){
    return {filters : this.fil, dates : this._dates}
  }
 
  public isObserving() : boolean{
    return this.observing;
  }
 
  public observable () : Observable<boolean> {
    this.observing = true;
    return this.data$;
  }
 

}
