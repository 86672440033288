import moment from "moment"
import { Moment } from "moment"
import { GLOBAL_FORMAT } from "../../data/const/format-date"


export interface IUtils {
    isNumber : (str : string|number) => boolean 
    getVideoEmbeded : (url : string) => string
    capitalize : (str : string) => string
    strToDate : (date : string, format ?: string ) => Moment,
    dateToStr : (date : Date, format ?:string) => string
}


export const Utils : IUtils = {

    getVideoEmbeded : (url : string) : string => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        const out =  (match && match[2].length === 11)
          ? match[2]
          : null;
    
    
        return "//www.youtube.com/embed/"+ out;
    },

    capitalize(str: string): string {
      return (str && str[0].toUpperCase() + str.slice(1)).replace('_',' ');
    }
    ,
    isNumber(value : string|number) : boolean {
      return !Number.isNaN(Number(value));
    },
    strToDate(date:string, format ?: string) : Moment{
      if(!format){
        format = GLOBAL_FORMAT;
      }  
      return moment(date,format);
    }
    ,
    dateToStr(date : Date , format ?:string) : string {
      if(!format){
        format = GLOBAL_FORMAT;
      }  
      return moment(date).format(format);
    }
}