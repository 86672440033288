import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-aside-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,MatIconModule,
    RouterModule

  ],
  templateUrl: './aside-dashboard.component.html',
  styleUrls: ['./aside-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideDashboardComponent {

  @Input() opened : boolean = true;
  @Output() toggle :  EventEmitter<any> = new EventEmitter();

  urlActive : string = "";
  startView : boolean = false;
  mode : MatDrawerMode = "side";

  constructor(private  router: Router, private auth : AuthService, private changes : ChangeDetectorRef){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlActive = event.urlAfterRedirects;
      }
    });
  }



  getDashboard(){
    return this.auth.getDashboard();
  }

  ngAfterViewInit(){
    setTimeout(()=>this.startView = true);
    this.changes.detectChanges();
  }

  close(){
    this.toggle.emit(this.opened = false);
  }

  toggleMenu(){
    this.toggle.emit(this.opened = !this.opened);
  }


  @HostListener('window:load', ['$event'])
  onLoad(event?: Event): void {
    const screenWidth = window.innerWidth;
    this.setScreenType(screenWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    // Obtener el ancho actual de la ventana
    const screenWidth = window.innerWidth;
    
    // Determinar el tipo de pantalla
    this.setScreenType(screenWidth);
  }

  private setScreenType(width: number): void {
   
    /*if (width < 576) {
      
    } else*/ if (width < 768) {
      this.mode = "over";
    // } else if (width < 992) {
      
    // } else if (width < 1200) {
     
    } else {
     this.mode = "side";
    }
  }

}
