import { CommonModule } from '@angular/common';
import { ApplicationRef, ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../../../core/services/api.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FORMAT_DATE, GLOBAL_FORMAT } from '../../../../data/const/format-date';
import moment from 'moment';
import { GENDER } from '../../../../data/const/GENDER';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../core/services/auth.service';
import { Iuser } from '../../../../core/interfaces/user.interface';
import { Rent } from '../../../../core/classes/rent';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { RequestManager } from '../../../../core/managers/RequestManager';
import { MatSelectCountryLangToken, MatSelectCountryModule } from '@angular-material-extensions/select-country';

@Component({
  selector: 'app-sing-up-form',
  standalone: true,
  providers: [
    provideMomentDateAdapter(FORMAT_DATE),
    {
      provide: MatSelectCountryLangToken,
      useValue: 'en'
    },
  ],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    MatDatepickerModule,
    MatButtonToggleModule,MatCheckboxModule, MatIconModule,
    MatTooltipModule,MatSelectCountryModule
    
  ],
  templateUrl: './sing-up-form.component.html',
  styleUrls: ['../../../../shared/styles/formfield-style.scss', './sing-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingUpFormComponent {

  @Output() error_signup : EventEmitter<any> = new EventEmitter();
  @Output() submiting : EventEmitter<any> = new EventEmitter();
  @Output() emailVerification: EventEmitter<any> = new EventEmitter();

  @Input() profile : boolean = false;


  registered : boolean = false;
  confirmed : boolean = false;

  disabledConfirm : boolean = false;
  errorConfirm : string = '';
  manyOfCodes : number = 6;
  readonly codes : number[] = new Array(this.manyOfCodes).fill(0);
  formConfirm : FormGroup = new FormGroup({
  });

  formRegister : FormGroup = new FormGroup({
    name : new FormControl(null, [Validators.required]),
    surname : new FormControl(null, [Validators.required]),
    birthdate : new FormControl(null, [Validators.required]),
    nationality : new FormControl(null, [Validators.required]),
    gender : new FormControl(null, [Validators.required]),
    phone : new FormControl(null, [Validators.required, Validators.pattern('^[+]?[0-9][0-9 -]*$')]),
    phone_code : new FormControl(null, [Validators.required]),
    email : new FormControl(null, [Validators.required]),
    password : new FormControl(null, [Validators.required]),
    repeat_password : new FormControl(null, [Validators.required]),
    terms : new FormControl(false)
  });

  readonly GENDER = GENDER;
  data : any;
  constructor( private injector: Injector, private api : ApiService,
    private requestManager : RequestManager,
    private snackbar : SnackbarService,
    private auth : AuthService,
    private ngZone: NgZone,
    private applicationRef: ApplicationRef){
    this.data = this.injector.get(MAT_DIALOG_DATA, {});
    for(let i = 0 ; i < this.manyOfCodes ; ++i){
      this.formConfirm.addControl('code'+i,new FormControl(null,[Validators.required]));
    }

    const user : Iuser | undefined = this.auth.getUser();
    if(user){
      user.birthdate = moment(user.birthdate).format('DD/MM/YYYY');
      this.formRegister.patchValue(user)
    }
  }

  getAge(birthDate: moment.Moment): number {
    const today = moment();
    let age = today.year() - birthDate.year();
    
    if (today.dayOfYear() < birthDate.dayOfYear()) {
      age--;
    }
    
    return age;
  }

  invalidAge(){

    const birthdate = this.formRegister.get('birthdate')?.value;

    const age = this.getAge(birthdate);

    if(age >= 18) return false;

    this.snackbar.text('Due to legal requirements, registration is restricted to users 18 years of age or older','error',10);
    return true;
  }

  confirm(){
    if(!this.formConfirm.valid){
      this.errorConfirm = 'Please, fill al the fields.'
      return;
    }

    const body : any = this.data.body ? this.data.body : {
      email : this.formRegister.get('email')?.value,
      password:  this.formRegister.get('password')?.value,
    };

    body.confirm_code = Object.values(this.formConfirm.getRawValue()).join('')
    this.submiting.emit(true);
    this.disabledConfirm = true;
    const method : string = 'activateAccount';
    const dialogRef = this.injector.get(MatDialogRef<SingUpFormComponent>);
    const REQUEST = this.api.post({body,method}).subscribe({
      next : (result)=>{
        if(result){
          this.confirmed = true;
          this.disabledConfirm = true;

          const data = result.data;
          const token = result.token;
          this.auth.login(data,token,this.data.request ? false :true);
          this.snackbar.close()
          dialogRef.close({logged : true});
          this.proceedToRequest(this.data.request);
          
        }else{
          this.errorConfirm = 'The code is incorrect. Please check and try again.';
          this.disabledConfirm = false;
        }

        this.submiting.emit(false);
        REQUEST.unsubscribe();
      },
      error : (error)=> {
        this.submiting.emit(false);
        this.disabledConfirm = false;
        this.errorConfirm = error;
        REQUEST.unsubscribe();
      }
    })

  }

  resend() {

    const body = this.data.body ? this.data.body : {
      email : this.formRegister.get('email')?.value,
      password: this.formRegister.get('password')?.value
    }
    const method : string = 'reSendEmailVerification';
    this.submiting.emit(true);
    this.disabledConfirm = true;
    const REQUEST = this.api.post({body,method}).subscribe({
      next : (result)=>{
        this.submiting.emit(false);
        this.errorConfirm = 'The code has been resent to your email address.'
        this.disabledConfirm = false;
        REQUEST.unsubscribe();
      },
      error : (error : any) => {
        this.errorConfirm = error;
        this.submiting.emit(false);
        this.disabledConfirm = false;

        REQUEST.unsubscribe();
      }
    })
  }

  register() {
    let leave : boolean = false;
    if(this.formRegister.invalid){
      if(!(this.formRegister.get('terms')?.value ?? false)){
        this.formRegister.get('terms')?.setErrors({'terms':'You need to accept the terms before continue.'})
      }

      if(!(this.formRegister.get('gender')?.value ?? false)){
        this.formRegister.get('gender')?.setErrors({'gender':'Please, select your gender.'})
      }

      leave = true;
    }

    if(this.differentPassword()) {
      this.formRegister.get('repeat_password')?.setErrors({'repeat_password':'The passwords do not match. Please ensure they are identical.'})
      leave = true;
    }

    
    leave = leave || this.invalidAge();


    if(leave) return;


    const body = this.formRegister.getRawValue();
    body.birthdate  = moment(body.birthdate, FORMAT_DATE.parse.dateInput).format(GLOBAL_FORMAT)

    delete body.repeat_password;

    this.submiting.emit(true);
    const method : string = "auth/sign-up";

    const REQUEST = this.api.post({body,method}).subscribe({
      next : (result)=>{
        this.submiting.emit(false);

        if(result){
          this.registered = true;
        }

        REQUEST.unsubscribe();
      },
      error : (error : any) => {
        this.submiting.emit(false);
        this.error_signup.emit(error.message ?? null);
        for(let e of Object.keys(error.errors)){
          this.formRegister.get(e)?.setErrors({[e]:error.errors[e]});
        }

        REQUEST.unsubscribe();
      }
    })
  }

  getHint() {
    return FORMAT_DATE.display.dateInput;
  }

  pasteCode(event: ClipboardEvent) {
    const text  : string | undefined =  event.clipboardData?.getData('text');
    for(let i = 0 ; i < this.manyOfCodes ; ++i){
      this.formConfirm.get('code'+i)?.setValue(null);
    }
    if(!text) return;
    if(Number.parseInt(text)==Number.NaN) return;

    for(let i = 0 ; i < Math.min(this.manyOfCodes,text.length) ; ++i){
      this.formConfirm.get('code'+i)?.setValue(text[i]);
    }

    if(text.length == this.manyOfCodes){
      this.confirm();
    }

  }

  proceedToRequest(rent : Rent){
    if(rent.isRequestingLoading()) return;

    rent.requesting(true);
    const body : any = {
      arrive : rent.getArrive()!,
      depart : rent.getDepart()!,
      about : rent.getAbout(),
    }
    this.requestManager.apply(rent.getRentRoom().id,body, {
      next: (result) => {
        this.snackbar.text('Request sent','ok',8);
        rent.requested(result);
        this.auth.forceUpadte();
      },
      error : (result) => {
        this.snackbar.text('This date is currently unavailable for booking','warn',8);
      },
      finalize: () => {
        rent.requesting(false);
      }
    })
  }


  getError(control: string) {
    let form : FormGroup = this.formRegister;
    if(this.registered){
      form = this.formConfirm;
    }
    const formControl : any = form.get(control)!;

    const errors : any = formControl.errors;
    
    if(errors?.required){
      return 'This field is required.';
    }
    if(errors?.[control] ?? false){
      return errors[control];
    }
    if(errors?.pattern ?? false){
      return 'Please enter a valid mobile phone number'
    }
    return '';
  }

  differentPassword(){
    const password = this.formRegister.get('password')?.value
    const repeat_password = this.formRegister.get('repeat_password')?.value

    return password != repeat_password;
  }
  codesContainer : any = null;
  validateCode(event : any,pos : number){
    this.errorConfirm = '';
    let value = event.target.value;
    if(Number.parseInt(value) == Number.NaN) return;
    this.codesContainer = this.codesContainer ? this.codesContainer : document.getElementById('codes-container');
    const control = this.formConfirm.get('code'+pos);
    if(!value || !value.trim()) return;
    if(value.length > 1){
      value = value.charAt(0);
      control?.setValue(value);
    }
    if(this.codesContainer.children.length > pos + 1){
      this.codesContainer.children[pos+1].focus();
    }
  }

  cleanCode(pos: number) {
    const control = this.formConfirm.get('code'+pos);
    control?.setValue(null);
  }

}
