import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID, TransferState } from "@angular/core";
import { finalize } from "rxjs";
import { ApiService } from "../services/api.service";
import { AuthService } from "../services/auth.service";
import { Iresponse } from "./RentManager";
import { Iuser } from "../interfaces/user.interface";
import { FileManager } from "./FileManager";



export interface Irequest {
  url : string,
  key ?: any,
  body ?: any,
  contentType?: string|null,
  responseType ?: string
}

@Injectable({
  providedIn: 'root'
})
export class Manager {

  protected constructor(protected api : ApiService, protected auth : AuthService, private _fileManager : FileManager,
        @Inject(PLATFORM_ID) protected platformId: Object,
        private transferState: TransferState){
  }


  protected getKey(key : any){
    return this.transferState.get(key, null);
  }

  protected removeKey(key: any){
    this.transferState.remove(key);
  }

  protected setKey(key : any, val : any){
    this.transferState.set(key,val);
  }

  private getKeyStored(key : any, response : Iresponse){
    if(!key) return false;
    const storedValue = this.getKey(key);

    if(storedValue){
      this.removeKey(key);
      if(storedValue === 'error'){
        if(response.error){
          response.error(null);
        }
        return true;
      }

      if(response.next)response.next(storedValue);
      return true;
    }
    return false;
  } 

  get fileManager() : FileManager{
    return this._fileManager
  }


  user() : Iuser | undefined {
    return this.auth.getUser()
  }

  private async _client (http: 'get'|'post'|'put'|'delete'|'patch',request : Irequest, response : Iresponse){

    const gotKey = this.getKeyStored(request.key,response);
    if(gotKey) return;

    if( ['get','delete'].includes(http) && request.body){

      let params : any = [];
      if(request.body){

        if(Array.isArray(request.body)){
          request.body.forEach((p : string)=>{
            if(p){
              params.push(p);
            }
          });
          
        }else{

          const keys = Object.keys(request.body);

          keys.forEach((k : string)=>{
            const value = request.body[k];
            if(value != 0 || value){
              params.push(`${k}=${value}`);
            }
          });

        }
      }
      request.url += `?${params.join('&')}`
      request.body = undefined;
    }


    if(request.body && request.body instanceof FormData){
      request.contentType = null;
    }
    
    const REST_API = this.api[http]({
        method:request.url,
        body: request.body,
        contentType : request.contentType,
        auth:this.auth.getToken(),
        responseType: request.responseType,
        fullError: true
      }).pipe(
        finalize( () => {
          REST_API.unsubscribe();
          if(response.finalize) response.finalize();
        })
      ).subscribe({
        next : (result)=>{
          if(request.key){
            if(isPlatformServer(this.platformId)){
              this.setKey(request.key,result);
            }
          }

          if(response.next)response.next(result)
        },
        error : (error)=>{
          if(error.status == 403 && error.error){
            if(error.error.detail && error.error.detail == "Invalid token."){
              this.auth.logout();
              if(typeof window !== 'undefined'){
                window.location.reload();
              }
              return;
            }
          }
          if(response.error) response.error(error.error);
          if(request.key){
            if(isPlatformServer(this.platformId)){
              this.setKey(request.key,'error');
            }
          }
        }
      });
  }

  protected async _get(request : Irequest, response : Iresponse) {
    this._client('get',request,response);
  }

  protected async _post(request : Irequest, response : Iresponse){
    this._client('post',request,response);
  }

  protected async _delete(request : Irequest, response : Iresponse){
    this._client('delete',request,response);
  }

  protected async _patch(request : Irequest, response : Iresponse){
    this._client('patch',request,response);
  }

  protected async _put(request : Irequest, response : Iresponse){
    this._client('put',request,response);
  }
}
