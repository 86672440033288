import { Pipe, type PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeURL',
  standalone: true,
})
export class safeURLPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer){}

  transform(value: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(value);
  }

}
