


export interface Iuser {
    id: number|string,
    email: string,
    birthdate: string,
    gender: number,
    locale: string,
    phone: string,
    nationality: any,
    rol: number,
    landlord : string,
    name : string,
    surname : string,
    phone_code : any

    information ?: {
        profile_icon ?:string
    } 
}



export enum Erol {
    SA = 0,
    ADMIN = 1,
    MODERATOR = 2,
    USER = 3,
    LANDLORD = 4,
}