<div class="calendar-wrapper" #calendarWrapper>
    <!-- Input trigger -->
    <div class="inputs-container" #inputsContainer>
        <div 
          class="date-input" 
          [class.active]="isOpen && activeInput === 'checkin'"
          [class.filled]="startDate.value"
          (click)="handleInputClick('checkin')"
        >
          <mat-icon class="icon">calendar_today</mat-icon>
          <div class="input-content" [ngStyle]="{'padding-top': startDate.value? '0.25em' : null}">
            <span class="floating-label">Arrive</span>
            {{ startDate.value ? formatDate(startDate.value) : 'Arrive' }}
          </div>
        </div>
      
        <div 
          class="date-input" 
          [class.active]="isOpen && activeInput === 'checkout'"
          [class.filled]="endDate.value"
          (click)="handleInputClick('checkout')"
        >
          <mat-icon class="icon">calendar_today</mat-icon>
          <div class="input-content" [ngStyle]="{'padding-top': endDate.value? '0.25em' : null}">
            <span class="floating-label">Depart</span>
            {{ endDate.value ? formatDate(endDate.value) : 'Depart' }}
          </div>
        </div>
    </div>
    <!-- Calendar dropdown -->
    
  </div>

  <ng-template #calendarContent>
    @if(openTimeout){
      <div class="calendar-dropdown" #calendarDropdown
  [class.show]="isOpen" 
  [class.hide]="!isOpen">
      <!-- Selected dates display -->
      <div class="selected-dates">
        <div class="date-section">
          <div class="date-label" style="font-family: Montserrat;">Arrive</div>
          <div class="date-value" style="font-family: Montserrat;">
            {{formatDate(startDate.value) || 'Select date'}}
          </div>
        </div>
        <div class="separator"></div>
        <div class="date-section">
          <div class="date-label" style="font-family: Montserrat;">Depart</div>
          <div class="date-value" style="font-family: Montserrat;"  >
            {{formatDate(endDate.value) || 'Select date'}}
          </div>
        </div>
      </div>

      <!-- Calendar navigation -->
      <div class="calendar-header">
          <button 
            class="nav-button"
            (click)="moveMonths(-1)"
            [disabled]="displayMonths[0].getMonth() === currentDate.getMonth()"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <div class="months-display">
            @for (date of displayMonths; track date.getTime()) {
              <span class="month-label" style="font-family: Montserrat;">
                {{MONTHS[date.getMonth()]}} {{date.getFullYear()}}
              </span>
            }
          </div>
          <button 
            class="nav-button"
            (click)="moveMonths(1)"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
      </div>

      
      <!-- Calendars grid -->
      <div class="calendars-grid">
        @for (monthDate of displayMonths; track monthDate.getTime()) {
          <div class="month-grid">
            <div class="weekdays">
              @for (day of WEEKDAYS; track day) {
                <div class="weekday">{{day}}</div>
              }
            </div>
            <div class="days">
              @for (date of getMonthDays(monthDate); track $index) {
                @if (!date) {
                  <div class="day-spacer"></div>
                } @else {
                  <button
                    class="day-button"
                    [class.selected]="isDateSelected(date)"
                    [class.in-range]="isDateInRange(date) && !isDateSelected(date)"
                    [class.start]="isStartDate(date)"
                    [class.end]="isEndDate(date)"
                    (click)="!isDateDisabled(date) && handleDateClick(date)"
                    (mouseenter)="handleDateHover(date)"
                    [disabled]="isDateDisabled(date)"
                  >
                    {{date.getDate()}}
                  </button>
                }
              }
            </div>
          </div>
        }
      </div>

      <!-- Footer -->
      <div class="calendar-footer">
        <button
          class="clear-button"
          (click)="clearDates()"
        >
          Clear dates
        </button>

        @if(searchButton){
          <button (click)="search()" type="button" style="cursor: pointer;
        height: 100%;
        padding: 0.55em;
        font-weight: 600;
        box-shadow: 3px 5px 7px -1px rgba(0, 0, 0, 0.5);
        font-size: 0.9rem;
        color: white;
        outline: none;
        border: none;
        border-radius: 4px;
        background-color: #db332a;
        font-family: Montserrat;">
          Search
        </button>
        }
      </div>
    </div>
  }
  </ng-template>
  