import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  
  private titleSuffix : string = " - Myothernest - Rent room in Dublin"
  constructor(private title : Title, private meta : Meta,
    private sanitizer: DomSanitizer) { }


  setTitle(text : string){
    this.title.setTitle(text + this.titleSuffix);
    this.meta.updateTag({property:"locale", content:'en_US'});
    this.meta.updateTag({property:"og:title", content:text + this.titleSuffix});
    this.meta.updateTag({property:"og:type", content:'article'});
    this.meta.updateTag({property: 'og:url', content: 'https://myothernest.com'});
    this.meta.updateTag({name:"twitter:title", content:text + this.titleSuffix});
    
  }

  setDescription(text : string){
    this.meta.updateTag({name: 'description', content : text});
    this.meta.updateTag({property: 'og:description', content : text});
    this.meta.updateTag({property: 'og:site_name', content: 'MyOtherNest'});
    this.meta.updateTag({name:"twitter:description", content:text});
  }
  
  setImage( image : string, alt: string){
    const baseUrl = `https://myothernest.com${image}`;
    const urlImage = this.sanitizer.sanitize(SecurityContext.URL,baseUrl);
    
    this.meta.updateTag({name:"twitter:card", content:'summary_large_image'});
    this.meta.updateTag({property: 'og:image',content: urlImage ||baseUrl});
    this.meta.updateTag({property: 'og:image:secure_url',content: urlImage ||baseUrl});
    this.meta.updateTag({name:"twitter:image", content:urlImage ||baseUrl});
    this.meta.updateTag({property: 'og:image:type',content: this.getImageMimeType(image)});
    this.meta.updateTag({property: 'og:image:width',content: `1200`});
    this.meta.updateTag({property: 'og:image:height',content: `630`});
    this.meta.updateTag({property: 'og:alt',content: alt});

    

  }

  getImageMimeType(filename : string) {
    // Extraer la extensión del archivo
    const ext = filename?.split('.').pop()?.toLowerCase();

    // Determinar el tipo MIME basado en la extensión
    switch (ext) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'webp':
            return 'image/webp';
        case 'svg':
            return 'image/svg+xml';
        case 'avif':
            return 'image/avif';
        default:
            return 'application/octet-stream';  // Tipo genérico para archivos desconocidos
    }
}


}
