import { Injectable, makeStateKey } from "@angular/core";
import { Irequest, Manager } from "./Manager";
import { Iresponse } from "./RentManager";
import { Iuser } from "../interfaces/user.interface";
import { environment } from "../../../environments/environment";


const USER_INFORMATION = makeStateKey<any>('USER_INFORMATION');

@Injectable({
  providedIn: 'root'
})
export class UserManager extends Manager{
   
   

    getUser() : Iuser|undefined{
        return this.auth.getUser();
    }

    isLandlord() : boolean {
        return this.auth.getUser()?.landlord ? true : false;
    }

    removeProfileImage(){
        this.auth.removeProfileIcon();
    }   

    profileImage(){
        this.auth.setProfileIcon(true);
    }

    getInformation(response : Iresponse) {
        
        const request : Irequest  = {
            url : 'user/information',
            key: USER_INFORMATION
        }

        this._get(request,response?? {})
    }

    getProfileImage(update=false) {
        if(!this.auth.hasProFileIcon()) return null;
        if(update) return environment.url + 'user-image/get?'+ Date.now();
        return environment.url + 'user-image/get';
    }

    deleteProfileImage(response : Iresponse){
        const request : Irequest = {
            url: 'user-image/delete',
            contentType: null
        }
        
    
        this._delete(request,response?? {});
    }

    changeFiles(data : {image?: File|undefined}, response ?: Iresponse){
        if(!data.image){
        return;
        }  
        const method : string = `user-image`;

        const formData = new FormData();
        if(data.image){
            formData.append('image',data.image)
        }
    
        const request : Irequest = {
            url: method,
            body : formData,
            contentType: null
        }
    
        this._post(request,response?? {});
        return true;
    }

    saveAddress(body : any , response : Iresponse){
        const request : Irequest = {
            url: 'user/update/address',
            body : body,
        }
    
        this._patch(request,response?? {});
    }

    saveEmergencyContact(body : any, response : Iresponse){


        const request : Irequest = {
            url: 'user/update/emergency-contact',
            body : body,
        }
    
        this._patch(request,response?? {});
    }


}
