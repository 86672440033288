<div class="container-text-snackbar" [ngStyle]="{'justify-content': data.close_button ? null : 'center'}">

    <span class="text-snackbar" >
        {{data.text}}
    </span>

    @if(data.close_button){
        <button type="button" class="button-close" (click)="close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    }

</div>