import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { UserManager } from '../../core/managers/UserManager';
import { UserIconService } from '../../core/services/userIconService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-icon',
  standalone: true,
  imports: [MatMenuModule,MatButtonModule,
    MatIconModule,
    RouterModule,
    CommonModule
  ],
  templateUrl: './user-icon.component.html',
  styleUrl: './user-icon.component.scss'
})
export class UserIconComponent {


  @Input() isActive : boolean = true;
  @Input() hover : boolean = true;

  iconMON : string = "/assets/icons/my other nest logos_icono1.png"
  profileImage ?: string|null;
  subs : Subscription []= [];
  urlActive : string = '';
  constructor(private auth : AuthService,private userManager : UserManager, private userIconService : UserIconService,private change : ChangeDetectorRef, private router : Router){
    
    this.profileImage = this.userManager.getProfileImage();

    this.subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          
          this.urlActive = event.urlAfterRedirects;
        }
      })
    )

    this.subs.push(
      this.userIconService.observable().subscribe((update : boolean)=>{

        if(update){
          this.profileImage = this.userManager.getProfileImage();
          this.change.detectChanges();
        }
      })
    );
  }


  ngOnDestroy(){
    this.subs.forEach((s)=>s.unsubscribe());
  } 

  handleError(event: any) {
    delete this.profileImage;
    this.userManager.removeProfileImage();
    event.target.src = this.iconMON;
    event.target.style.objectFit = 'contain';
  }


  private isHoverMenu : boolean = false;
  private _timeoutMenu  : any;

  private isHoverIcon : boolean = false;
  private _timeoutIcon : any ;

  clearTimeouts (){
    if(this._timeoutIcon){
      clearTimeout(this._timeoutIcon);
      delete this._timeoutIcon;
    }

    if(this._timeoutMenu){
      clearTimeout(this._timeoutMenu);
      delete this._timeoutMenu;
    }
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    if(!this.hover)return;
    this.clearTimeouts();
    this.isHoverIcon  = true;
    menuTrigger.openMenu();
  }

  blockMenuTop(){
    this.clearTimeouts();
    this.isHoverMenu = true;
    this.isHoverIcon = false;
  }

  closeMenu(menuTrigger : MatMenuTrigger,level:number = 1){
    if(level == 1){
      
      this._timeoutIcon = setTimeout(()=>{
        this.isHoverIcon = false;
        menuTrigger.closeMenu();
      },100)

      return;
    }
    
    this._timeoutMenu = setTimeout(()=>{
      this.isHoverMenu = false;
      menuTrigger.closeMenu();
    },100)

  }

  getDashboard(){
    return this.auth.getDashboard();
  }
}
