import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SEOService } from './core/services/seo.service';
import { AuthService } from './core/services/auth.service';
import { ApiService } from './core/services/api.service';
import { safeURLPipe } from './core/services/safeURL.pipe';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes,withInMemoryScrolling({
      scrollPositionRestoration: "top",
    })),
    provideAnimationsAsync(), provideClientHydration(withEventReplay()),
    SsrCookieService,safeURLPipe,
    SEOService, AuthService, ApiService,
    
  ]
};
