



import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileManager{

  
    base64ToBlob(base64Image: string): Blob {
        // Obtener la parte de datos del base64
        const parts = base64Image.split(';base64,');
        const imageType = parts[0].split(':')[1];
        const decodedData = window.atob(parts[1]);
        
        // Crear array de bytes
        const uInt8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }
        
        // Retornar como blob
        return new Blob([uInt8Array], { type: imageType });
    }

    public removeBlob(i : string,fileName?: string|null){
        if(!i.startsWith('blob')){
            return i;
        }
        const extension = fileName ? fileName.substring(fileName.lastIndexOf('.')) : '';
        return i.replace('blob:'+window.location.origin+'/',  '')+extension;
    }

    removeMetadata(file: File): Promise<File> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                // Crear canvas
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                // Dibujar imagen en canvas
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0);

                // Convertir canvas a Blob/File
                canvas.toBlob((blob) => {
                if (blob) {
                    // Crear nuevo archivo sin metadata
                    const cleanFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: new Date().getTime()
                    });
                    resolve(cleanFile);
                } else {
                    reject('Error creating blob');
                }
                }, file.type);
            };

                img.onerror = () => reject('Error loading image');

                // Cargar imagen
                const reader = new FileReader();
                reader.onload = (e) => img.src = e.target?.result as string;
                reader.readAsDataURL(file);
            });
    }
}
